import { Locale } from '@core/Entities/Locale/Locale.entity';
import { useSearchWidgetTracking } from '@core/Hooks/Search/UseSearchWidgetTracking';
import Button from '@gds/Button/Button';

import Link from 'next/link';

import { searchWidgetMessages } from '../../../SearchWidget/SearchWidget.messages';

import styles from './BuyOnlineCta.module.css';

interface CtaProps {
  strings: Record<keyof typeof searchWidgetMessages, string>;
  buyOnlineCtaUrl: string;
  locale: Locale;
}

const BuyOnlineCta = ({ strings, buyOnlineCtaUrl, locale }: CtaProps) => {
  const { trackLinkCta } = useSearchWidgetTracking(buyOnlineCtaUrl, strings.buyOnlineCta, locale);

  return (
    <div className={styles.buyOnlineCta}>
      <div className={styles.or}>
        <span className={styles.copy}>{strings.or}</span>
      </div>
      <Button
        Component={Link}
        aria-label="buyonline-button"
        className={styles.button}
        fullWidth
        href={buyOnlineCtaUrl}
        type="submit"
        variant="outlined"
        onClick={trackLinkCta}
      >
        {strings.buyOnlineCta}
      </Button>
    </div>
  );
};

export default BuyOnlineCta;
