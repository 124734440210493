'use client';
import { IMAGE_SIZES } from '@core/Constants/ImageCarousel.constants';
import { HeycarImage } from '@core/Components/HeycarImage/HeycarImage';
import { heycarLoader } from '@core/Components/HeycarImage/HeycarLoader';
import { kontentLoader } from '@core/Components/KenticoImage/KontentLoader';
import { Locale } from '@core/Entities/Locale/Locale.entity';
import { Vehicle } from '@core/Entities/Vehicle/Vehicle.entity';
import { VehicleImage } from '@core/Entities/Vehicle/VehicleImage.entity';
import { isHeycarConnectDealer } from '@core/Utils/Vehicle/IsHeycarConnect';
import HeycarConnect from '@gds/ConnectBadge/connect.webp';
import { Image as Pic } from '@gds/Icons/Paths/Image';
import RacLogo from '@gds/RacBadge/rac.webp';
import Image from 'next/image';

import styles from './HeroImage.module.css';

const IMAGE_SIZE = 70;

export const HeroImage = ({
  image,
  index,
  isRacApproved,
  isHeycarConnect,
  totalImages,
  makeModelLabel,
  locale,
  vehicle,
  isInactive,
}: {
  vehicle?: Vehicle;
  image: VehicleImage;
  index?: number;
  isRacApproved?: boolean;
  isHeycarConnect?: boolean;
  totalImages?: number;
  makeModelLabel: string;
  locale: Locale;
  isInactive?: boolean;
}) => {
  if (!image) return null;

  const mainImgString = locale === 'uk' ? 'Main listing image' : "Image de l'annonce principale";
  const hasHeycarConnect = isHeycarConnect && isHeycarConnectDealer(vehicle!);

  const loader = image.isKontent ? kontentLoader : heycarLoader;
  const logoSrc = isRacApproved ? RacLogo : hasHeycarConnect ? HeycarConnect : null;

  const logoAlt = isRacApproved
    ? 'rac approved logo'
    : hasHeycarConnect
    ? 'heycar connect logo'
    : '';

  return (
    <figure className={styles.wrpr}>
      <HeycarImage
        src={image?.url}
        alt={image?.altText || `${mainImgString} - ${makeModelLabel}`}
        className={styles.img}
        sizes={IMAGE_SIZES.small}
        loader={loader}
        forceVisible={(index ?? 100) <= 1}
        preload
        isInactive={isInactive}
      />

      {logoSrc && (
        <aside className={styles.racBadge}>
          <Image src={logoSrc} width={IMAGE_SIZE} height={IMAGE_SIZE} alt={logoAlt} />
        </aside>
      )}

      {totalImages && (
        <aside className={styles.imgsNum}>
          <Pic />
          {totalImages}
        </aside>
      )}
    </figure>
  );
};
