import { FC } from 'react';

import {
  nodeParse,
  transformToPortableText,
  PortableTextComponent,
} from '@kontent-ai/rich-text-resolver';
import { PortableText, PortableTextTypeComponentProps } from '@portabletext/react';
import cn from 'classnames';

import AnchorDestination from '../AnchorDestination/AnchorDestination';

import styles from './RichText.module.css';

type RichTextProps = {
  richText: string | undefined;
  richTextLinkedItems?: Record<string, any>[];
  noPadding?: boolean;
  className?: string;
};

export const RichText: FC<RichTextProps> = ({
  noPadding,
  richText,
  richTextLinkedItems,
  className,
}) => {
  const classNames = cn(styles.richText, noPadding && styles.noPadding, className);
  const parsedTree = nodeParse(richText ?? '');
  const portableText = transformToPortableText(parsedTree);

  return (
    <div className={classNames}>
      <PortableText
        value={portableText}
        components={generatePortableTextComponents(richTextLinkedItems ?? [])}
      />
    </div>
  );
};

function generatePortableTextComponents(linkedItems: Record<string, any>[]) {
  return {
    types: {
      span: ({ value }: PortableTextTypeComponentProps<PortableTextComponent>) => {
        return <span>{value.text}</span>;
      },
      component: ({ value }: PortableTextTypeComponentProps<PortableTextComponent>) => {
        const linkedItem = linkedItems.find(item => item.__codename === value.component._ref);
        switch (linkedItem?.__type) {
          case 'page_builder_anchor_destination_component': {
            return <AnchorDestination anchorId={linkedItem.anchorId} />;
          }
          default: {
            return null;
          }
        }
      },
    },
  };
}
