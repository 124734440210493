import camelCase from 'lodash.camelcase';

import { KenticoData, KenticoItem } from '@core/Entities/Kentico/Kentico.entity';

const kenticoItemCache = new Map<string, Record<string, any>>();

export function simplifyKenticoItem(
  item: KenticoItem,
  processedCodenames?: string[],
): Record<string, any> {
  const itemCodename = item.system.codename;
  const currentProcessedCodenames = processedCodenames ? processedCodenames : [];
  if (currentProcessedCodenames.includes(itemCodename) || kenticoItemCache.has(itemCodename)) {
    return kenticoItemCache.get(itemCodename) || item.elements;
  }
  currentProcessedCodenames.push(itemCodename);

  const simplifiedItem = Object.entries(item.elements).reduce((simplified, [key, element]) => {
    const newKey = camelCase(key);
    simplified.__type = item.system.type;
    simplified.__codename = itemCodename;
    simplified.__id = item.system.id;
    simplified.__language = item.system.language;

    // Rich text can contain linkedItems. Sometimes with valid html and sometimes without
    // If no valid html we don't store the rich text value but we do want to store the linkedItems
    // If valid html we want to store both but under different keys
    if (element.type === 'rich_text' && isValidRichTextField(element.value)) {
      simplified[newKey] = element.value;
    }

    if (element.linkedItems) {
      const linkedItemsKey = simplified[newKey] ? `${newKey}LinkedItems` : newKey;

      simplified[linkedItemsKey] = element.linkedItems.map((value: any) =>
        simplifyKenticoItem(value, currentProcessedCodenames),
      );

      return simplified;
    }

    if (element.value?.[0]?.url) {
      simplified[newKey] = element.value?.map((value: any) => value?.url);
      simplified[newKey + 'AltTexts'] = element.value?.map((value: any) => value?.description);
      return simplified;
    }

    if (element.value !== undefined) {
      simplified[newKey] = element.value;
      return simplified;
    }

    return simplified;
  }, {} as Record<string, any>);

  kenticoItemCache.set(itemCodename, simplifiedItem);
  return simplifiedItem;
}

export function simplifyKenticoData<T extends Record<string, any>>(data: KenticoData): T {
  const simplifiedItem = simplifyKenticoItem(data.item) as T;
  kenticoItemCache.clear();
  return simplifiedItem;
}

export const sanitizeRichText = (value?: string) => {
  if (!value || value === '<p><br></p>') return undefined;

  return value;
};

function isValidRichTextField(richTextValue: string) {
  return (
    richTextValue.includes('<h1>') ||
    richTextValue.includes('<h2>') ||
    richTextValue.includes('<h3>') ||
    richTextValue.includes('<p>') ||
    richTextValue.includes('<ul>') ||
    richTextValue.includes('<ol>') ||
    richTextValue.includes('<li>') ||
    richTextValue.includes('<table>')
  );
}
