import Link from 'next/link';

import { Locale } from '@core/Entities/Locale/Locale.entity';
import { useSearchWidgetTracking } from '@core/Hooks/Search/UseSearchWidgetTracking';
import Button from '@gds/Button/Button';

import styles from './AutobizCta.module.css';

interface CtaProps {
  strings: { autobizCta: string; or: string };
  locale: Locale;
}

const AutobizCta = ({ strings, locale }: CtaProps) => {
  const { trackLinkCta } = useSearchWidgetTracking(
    '/fr/vendre-sa-voiture',
    strings.autobizCta,
    locale,
  );

  return (
    <div className={styles.autobizCta}>
      <div className={styles.or}>
        <span className={styles.copy}>{strings.or}</span>
      </div>
      <Button
        Component={Link}
        aria-label="autobiz-button"
        className={styles.button}
        fullWidth
        href="/fr/vendre-sa-voiture"
        type="submit"
        variant="outlined"
        onClick={trackLinkCta}
      >
        {strings.autobizCta}
      </Button>
    </div>
  );
};

export default AutobizCta;
