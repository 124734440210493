import { Locale } from '../../Entities/Locale/Locale.entity';
import { FilterKey, FilterParams } from '../../Entities/Search/QueryParams.entity';
import { Filter } from '../../Entities/Search/SearchFilters/Filter.entity';
import { createQueryStrFromFilters } from '../Search/CreateQueryStrFromFilters';
import { createPathFromFilters } from '../Search/CreatePathFromFilters';
import { AllFlags } from '@growthBookExperimentation/Entities/FlagKeys.entities';

const validateUrl = (
  locale: Locale,
  filtersSlug: string[],
  searchParams: { [key: string]: string | string[] | undefined },
  combinedFilterParams: FilterParams,
  root = 'autos',
  flags?: AllFlags,
) => {
  //filtersSlug should be key value pairs, it there is an odd number we cant parse any of them
  if (filtersSlug && filtersSlug.length % 2 !== 0)
    return {
      isValid: false,
      alternativePath: `/${locale}/${root}`,
    };

  const searchFilterArray: Filter[] = Object.keys(searchParams)
    .filter(fltrKey => fltrKey !== 'region' && fltrKey !== 'area' && fltrKey !== 'dealership-id') // Exclude 'region', 'area' and 'dealership-id' keys
    .map(fltrKey => ({
      id: fltrKey as FilterKey,
      value: searchParams[fltrKey as FilterKey],
    }));

  const queryString = createQueryStrFromFilters(searchFilterArray);

  const receivedPath = `/${locale}/${root}${
    combinedFilterParams.region ? `/${combinedFilterParams.region}` : ''
  }${combinedFilterParams.area ? `/${combinedFilterParams.area}` : ''}${
    combinedFilterParams['dealership-id'] && root === 'dealerships'
      ? `/${combinedFilterParams['dealership-id']}`
      : ''
  }${filtersSlug && filtersSlug.length > 0 ? `/${filtersSlug.join('/')}` : ''}${
    queryString ? `?${queryString}` : ''
  }`;

  const filterArray: Filter[] = Object.keys(combinedFilterParams).map(fltrKey => ({
    id: fltrKey as FilterKey,
    value: combinedFilterParams[fltrKey as FilterKey],
  }));

  const featureFlaggedFilterArray = filterArray.filter(filter => {
    if (
      (locale === 'fr' && !flags?.heycarConnect && filter.value === 'HEYCAR_CONNECT') ||
      (locale === 'fr' && !flags?.frReservation && filter.id === 'ecom')
    )
      return null;
    return filter;
  });

  const expectedPath = createPathFromFilters(featureFlaggedFilterArray, locale, root);

  return {
    isValid: expectedPath === receivedPath,
    alternativePath: expectedPath,
  };
};

export { validateUrl };
