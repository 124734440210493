import { postcodeAtom } from '@core/Atoms/Postcode/Postcode.atom';
import { LeadFormState } from '@core/Atoms/Pdp/FormState.atom';
import { searchParamsAtom } from '@core/Atoms/SearchParams/SearchParams.atom';
import { userIdAtom } from '@core/Atoms/User/UserId.atom';
import { Locale } from '@core/Entities/Locale/Locale.entity';
import { trackCustomEvent } from '@tracking/Utils/TrackCustomEvent';
import { useAtomValue } from 'jotai';

import { SearchParamsType } from '@core/Atoms/SearchParams/SearchParams.atom';
import { ctaClickedToEvent } from '@tracking/Schemas/CtaClicked/CtaClickedToEvent';
import { marketingToEvent } from '@tracking/Schemas/Marketing/MarketingToEvent';
import { userContextToEvent } from '@tracking/Schemas/UserContext/UserContextToEvent';

export const useSearchWidgetTracking = (searchUrl: string, label: string, locale: Locale) => {
  const userId = useAtomValue(userIdAtom);
  const searchParams = useAtomValue(searchParamsAtom);
  const postcode = useAtomValue(postcodeAtom);

  const genericProps = {
    searchParams,
    userId,
    formState: buildFormStateObj(postcode[locale]),
    ctaUrl: searchUrl,
    ctaWording: label,
  };

  const trackLinkCta = () => {
    const { event, context } = generateSearchCtaTracking({
      ...genericProps,
      ctaType: 'link',
    });

    trackCustomEvent({ event, context });
  };

  const trackSubmitCta = () => {
    const { event, context } = generateSearchCtaTracking({
      ...genericProps,
      ctaType: 'button',
    });

    trackCustomEvent({ event, context });
  };

  return { trackLinkCta, trackSubmitCta };
};

function generateSearchCtaTracking({
  searchParams,
  userId,
  formState,
  ctaType,
  ctaWording,
  ctaUrl,
}: {
  searchParams: SearchParamsType;
  userId: string | null | undefined;
  formState: LeadFormState;
  ctaType: string;
  ctaWording: string;
  ctaUrl: string;
}) {
  const marketingDataObj = {
    marketingValues: { ...(searchParams ?? {}) },
  };

  const userData = {
    userId,
    values: { ...formState },
  };
  const ctaEventObj = {
    ctaType,
    ctaWording,
    targetUrl: ctaUrl,
  };

  // event actions
  const ctaEvent = ctaClickedToEvent(ctaEventObj);

  // event entities
  const userEvent = userContextToEvent(userData);
  const marketingEvent = marketingToEvent(marketingDataObj);

  const context = [userEvent, marketingEvent];

  return { event: ctaEvent, context };
}

function buildFormStateObj(postcode: string) {
  return {
    firstName: '',
    lastName: '',
    fullName: '',
    email: '',
    optIns: undefined,
    postcode: postcode,
  } as LeadFormState;
}
