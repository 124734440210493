/* eslint-disable no-useless-escape */
export const NAME_VALIDATION = /^\s*([A-Za-z]{1,}([\.,] |[-']| )?)+[A-Za-z]+\.?\s*$/;

//Allow only alphabets
export const FR_NAME_VALIDATION =
  /^\s*(?![\d\s])((?:[A-Za-z]{1,}([\.,] |[-']| )?)+[A-Za-z]+\.?)\s*$/;

export const EMAIL_VALIDATION =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// valid phone examples: +447222555555 | +44 7222 555 555 | 07222555555 | 07222 555 555 | (0722) 5555555 #2222 | 0722 5555555 #2222
export const UK_PHONE_VALIDATION =
  /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?#(\d{4}|\d{3}))?$/;

export const FR_PHONE_VALIDATION = /^(?:\+33|0)\s*[1-9](?:\s*\d){8}$/;

// valid phone examples: +491234567890 | +49 123 456 7890 | 01234567890 | 0123 456 7890
export const DE_PHONE_VALIDATION = /^(?:\+49|0)\s*[1-9]\d{1,4}(?:\s*\d{1,8}){1,2}$/;

export const CONCIERGE_ACTIVATION_TOKEN_VALIDATION = /(^hey-[A-Za-z\d]{6}$)/;

export const NUMBER_VALIDATION_8 = /[0-9]{8}/;

export const NUMBER_VALIDATION_MAX_15 = /^[0-9]{1,15}$/;

export const SIMPLE_NUMBER_VALIDATION = /[0-9]+/;

// with or without hyphens. 112233 || 11-22-33
export const SORT_CODE = /^([0-9]){6}$|^([0-9]){2}([-])([0-9]){2}([-])([0-9]){2}$/;

// UK drivers licence. first 5 chars are letters or 9s, followed buy 11 char alphanumeric
export const UK_DRIVERS_LICENCE = /^([A-Za-z|9]){5}([A-Za-z|0-9]){11}$/;

export const VWFS_NAME_REGEX = /^[a-zA-Z\u00c0-\u018d\-][a-zA-Z\u00c0-\u018d\-\ \n]*$/i;

// Number plate RegEx from gov.uk (does not accept spaces unfortunately so we need to remove them)
export const NUMBER_PLATE_REGEX =
  /(^[A-Z]{2}[0-9]{2}\s?[A-Z]{3}$)|(^[A-Z][0-9]{1,3}[A-Z]{3}$)|(^[A-Z]{3}[0-9]{1,3}[A-Z]$)|(^[0-9]{1,4}[A-Z]{1,2}$)|(^[0-9]{1,3}[A-Z]{1,3}$)|(^[A-Z]{1,2}[0-9]{1,4}$)|(^[A-Z]{1,3}[0-9]{1,3}$)|(^[A-Z]{1,3}[0-9]{1,4}$)|(^[0-9]{3}[DX]{1}[0-9]{3}$)/i;

export const FR_NUMBER_PLATE_REGEX = /^[A-Z]{2}-\d{3}-[A-Z]{2}$/;

export const UK_POSTCODE_REGEX = /^[a-z]{1,2}\d[a-z\d]?\s*\d[a-z]{2}$/i;

export const FR_POSTCODE_REGEX = /^[0-9]{5}$/i;

export const DE_FR_DEALER_ID =
  /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/;
