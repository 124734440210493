'use client';

import { useModal } from '@core/Components/Modal/UseModal';
import { Locale } from '@core/Entities/Locale/Locale.entity';
import { Typography } from '@gds/Typography/Typography';

import { PriceRatingModal } from '../PriceRatingModal/PriceRatingModal';

import { priceRatingGaugeMessages } from './PriceRatingGauge.messages';

import styles from './PriceRatingGauge.module.css';

const PriceRatingGauge = ({
  locale,
  rating,
  strings,
  clickable,
}: {
  locale: Locale;
  rating: string;
  strings: Record<keyof typeof priceRatingGaugeMessages, string>;
  clickable?: boolean;
}) => {
  const { openModal } = useModal();
  const handleOpenModal = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!clickable) return;
    e.preventDefault();
    openModal(<PriceRatingModal locale={locale} strings={strings} />, {
      header: strings.modalHeader,
    });
  };

  const ratingLabelMap = {
    great: strings.greatPrice,
    good: strings.goodPrice,
    fair: strings.fairPrice,
  };

  if (!rating || rating === 'high' || (locale === 'fr' && rating === 'fair')) return null;

  return (
    <div
      className={`${styles.priceRating} ${clickable ? styles.clickable : ''}`}
      onClick={handleOpenModal}
    >
      <div className={styles.gauge}>
        <Gauge variant={rating} />
        <div className={`${styles.scoreArrow} ${styles[rating]}`}>
          <Arrow />
        </div>
      </div>
      <Typography
        variant="caption1"
        Component="span"
        className={`${styles.label} ${styles[rating]}`}
      >
        {ratingLabelMap[rating as keyof typeof ratingLabelMap]}
      </Typography>
    </div>
  );
};

const Gauge = ({ variant = 'fair' }) => (
  <svg width="28" height="20" xmlns="http://www.w3.org/2000/svg" fill="none">
    <g>
      <ellipse
        strokeWidth="0"
        ry="16.5"
        rx="11.875"
        id="svg_5"
        cy="18.25"
        cx="13.875"
        fill="#fff"
      />
      <g id="svg_2" clipPath="url(#clip0_25536_32612)">
        <ellipse
          id="svg_3"
          ry="5.38462"
          rx="5.44444"
          cy="14.6154"
          cx="14"
          className={`${styles.circle}  ${styles[variant]}`}
        />
        <path
          id="svg_4"
          className={`${styles.circle}  ${styles[variant]}`}
          d="m3.11111,13.8461c0,-5.94763 4.87512,-10.76918 10.88889,-10.76918c6.0138,0 10.8889,4.82155 10.8889,10.76918c0,2.3882 -0.7842,4.5911 -2.1126,6.3761l3.6538,0c1.0029,-1.9089 1.5699,-4.0781 1.5699,-6.3761c0,-7.64697 -6.268,-13.8461 -14,-13.8461c-7.73199,0 -14,6.19913 -14,13.8461c0,2.298 0.56701,4.4672 1.56987,6.3761l3.65386,0c-1.32841,-1.785 -2.11262,-3.9879 -2.11262,-6.3761z"
          clipRule="evenodd"
          fillRule="evenodd"
        />
      </g>
    </g>
  </svg>
);

const Arrow = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="4.5" height="13.5" viewBox="0 0 4.5 13.5">
    <g>
      <path
        d="M1.529,0.487 C1.621,-0.154 2.57,-0.166 2.679,0.472 L4.469,10.959 C4.694,12.276 3.66,13.479 2.285,13.5 C0.905,13.52 -0.168,12.342 0.022,11.015 L1.529,0.487 z"
        fill="#040F21"
      />
    </g>
  </svg>
);

export default PriceRatingGauge;
