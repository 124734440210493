'use client';
import { Locale } from '@core/Entities/Locale/Locale.entity';
import { VehicleImage } from '@core/Entities/Vehicle/VehicleImage.entity';
import StarReviewRating from '@gds/ReviewRating/StarReviewRating';
import { Typography } from '@gds/Typography/Typography';
import { HeroImage } from '@vehicle-tile/Components/HeroImage/HeroImage';
import Link from 'next/link';

import styles from './ReviewCarouselTile.module.css';

type Props = {
  slug: string;
  imgGallery: VehicleImage[];
  title: string;
  subTitle: string;
  rating: number;
  strs: Record<string, any>;
  locale: Locale;
};

export const ReviewCarouselTile: React.FC<Props> = ({
  slug,
  imgGallery,
  title,
  subTitle,
  rating,
  strs,
  locale,
}) => {
  return (
    <Link href={slug}>
      <HeroImage
        image={imgGallery[0]}
        totalImages={imgGallery.length}
        makeModelLabel={title}
        index={0}
        locale={locale}
      />
      <section className={styles.reviewsBody}>
        <Typography Component="h3" variant="subheading2" className={styles.title}>
          {title}
        </Typography>
        <Typography variant="subheading4" className={styles.subTitle}>
          {subTitle}
        </Typography>

        {rating > 0 && (
          <div className={styles.reviewsRating}>
            <StarReviewRating rating={rating} />
            <Typography Component="p" variant="caption1" className={styles.ratingDivider}>
              {strs.heycarRating} {''}
              {rating}/5
            </Typography>
          </div>
        )}
        <Typography variant="buttonLink2" className={styles.reviewSlug}>
          {strs.readFullReview}
        </Typography>
      </section>
    </Link>
  );
};
