import { Vehicle } from '@core/Entities/Vehicle/Vehicle.entity';

import { ListingEvent } from './ListingEvent';
import { listingSchema } from './ListingSchema';
import { VehicleListingSubset } from './extractVehicleListingSubset';

export const vehicleToListingEvent = (vehicle: Vehicle | VehicleListingSubset): ListingEvent => {
  const event: ListingEvent = {
    schema: listingSchema.ref,
    data: !vehicle
      ? {}
      : {
          advertiser_id: String(vehicle?.dealer?.id!),
          body_style: vehicle?.spec?.bodyType ?? undefined,
          colour: vehicle?.spec?.color ?? undefined,
          condition: vehicle?.details?.stockCondition ?? undefined,
          created_at: vehicle?.details?.createdAt ?? undefined,
          listing_id: vehicle?.heycarId,
          make: vehicle?.make?.label,
          mileage: vehicle?.details?.mileage!,
          model: vehicle?.model?.label,
          monthly_price: vehicle?.pricing?.monthlyPrice ?? undefined,
          price: vehicle?.pricing?.price,
          registration: vehicle?.details?.registration ?? undefined,
          variant: vehicle?.variant?.label ?? undefined,
          vehicle_name: vehicle?.prettyName ?? undefined,
          vin: vehicle?.vin ?? undefined,
          year: vehicle?.details?.year ?? undefined,
          vehicle_available: vehicle?.details.status === 'active',
        },
  };

  return event;
};
