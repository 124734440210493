'use client';

import { useEffect } from 'react';

import Grid from '@gds/Grid/Grid';
import { Typography } from '@gds/Typography/Typography';
import { FullWidth } from '@page-builder/Components/FullWidth/FullWidth';

import { hasSetCookiesAtom } from '@tracking/Atoms/HasSetCookies.atom';
import { useAtomValue } from 'jotai';
import Image from 'next/image';
import { usePathname } from 'next/navigation';
import Script from 'next/script';

import styles from './FrValuationHero.module.css';

interface Props {
  backgroundColor: string;
  image: Array<string>;
  header: string;
  subheader: string;
}

const apiKey = process.env.NEXT_PUBLIC_WKDA_API_KEY!;

const IFrameHero = ({ backgroundColor, image, header, subheader }: Props) => {
  const ImageWrapper = 'span';
  const wrapperStyle = { background: backgroundColor };
  const hasSetCookies = useAtomValue(hasSetCookiesAtom);
  const pathname = usePathname();

  const initialise = () => {
    // @ts-expect-error - WkdaPartner is not defined
    window.WkdaPartner?.init?.(apiKey, { locale: 'fr-FR' });
  };

  useEffect(() => {
    // @ts-expect-error - gdprConsent is not defined
    window.gdprConsent = function () {
      if (hasSetCookies) {
        return ['essential', 'analytical', 'marketing'];
      } else return [];
    };

    initialise();

    document.onreadystatechange = initialise;

    return () => {
      document.onreadystatechange = null;
    };
  }, [pathname]);

  return (
    <>
      <FullWidth>
        <div style={wrapperStyle} id="valuation-hero">
          <Grid.Container className={styles.heroWrapper}>
            <div className={styles.textWrapper}>
              <Typography className={styles.heading} variant="h1">
                <span>{header}</span>
              </Typography>
              <div className={styles.mobileImageContainer}>
                <Image src={image[0]} alt="Photo of a car" width={800} height={80} />
              </div>
              <Typography className={styles.subHeading} variant="h5">
                {/* eslint-disable @typescript-eslint/naming-convention */}
                <span dangerouslySetInnerHTML={{ __html: subheader }} />
              </Typography>
            </div>
            <ImageWrapper className={styles.imageWrapper}>
              <div className={styles.imageContainer}>
                <Image src={image[0]} alt="Photo of a car" width={800} height={800} />
              </div>
            </ImageWrapper>
          </Grid.Container>
        </div>
      </FullWidth>
      <div className={styles.formWrapper}>
        <div id="wkda-funnel-container"></div>
        <Script
          key={pathname}
          src="https://static.wirkaufendeinauto.de/wkda-frontend/partner-funnel-iframe-manager/index.latest.js"
          strategy="afterInteractive"
          onLoad={initialise}
        />
      </div>
    </>
  );
};

export default IFrameHero;
