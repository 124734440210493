import { useEffect, useRef, useState } from 'react';

import { ITEM_PP_THRESHOLD, ITEM_WIDTH } from './CompanyReview.constants';

export const useCompanyReviews = (noOfReviews: number) => {
  const [currentPage, setCurrentPage] = useState(0);
  const container = useRef<HTMLDivElement>(null);
  const fullItemsPerPage = useRef(1);
  const totalPages = noOfReviews;
  const isPrevNavButtonEnabled = currentPage > 0;
  const enableNextNavButton = () => {
    return currentPage + fullItemsPerPage.current < totalPages;
  };

  const setItemsPerPage = () => {
    if (container.current) {
      const containerWidth = container.current?.clientWidth;
      fullItemsPerPage.current = Math.floor((containerWidth + ITEM_PP_THRESHOLD) / ITEM_WIDTH);
      setCurrentPage(0);
    }
  };

  const handleSwipe = (e: TouchEvent) => {
    const touch = e.changedTouches[0];
    const startX = touch.clientX;

    const handleTouchMove = (moveEvent: TouchEvent) => {
      const moveTouch = moveEvent.changedTouches[0];
      const moveX = moveTouch.clientX;
      const diffX = startX - moveX;

      if (Math.abs(diffX) > ITEM_PP_THRESHOLD) {
        if (diffX > 0) {
          handleMove('right');
        } else {
          handleMove('left');
        }
        window.removeEventListener('touchmove', handleTouchMove);
      }
    };

    window.addEventListener('touchmove', handleTouchMove);
  };

  const handleMove = (direction: 'left' | 'right') => {
    if (direction === 'left' && isPrevNavButtonEnabled) {
      setCurrentPage(currentPage - 1);
    } else if (direction === 'right' && enableNextNavButton()) {
      setCurrentPage(currentPage + 1);
    }
  };

  //set the number of items per page on resize
  useEffect(() => {
    setItemsPerPage();
    window.addEventListener('resize', setItemsPerPage);

    return () => window.removeEventListener('resize', setItemsPerPage);
  }, [container]);

  //add event listener for touchstart
  useEffect(() => {
    const containerElement = container.current;
    if (containerElement) {
      containerElement.addEventListener('touchstart', handleSwipe);
    }

    return () => {
      if (containerElement) {
        containerElement.removeEventListener('touchstart', handleSwipe);
      }
    };
  }, [container, currentPage]);

  const transX = currentPage * ITEM_WIDTH * -1;

  return {
    containerRef: container,
    handleMove,
    isPrevNavButtonEnabled,
    enableNextNavButton,
    transX,
  };
};
