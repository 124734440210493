'use client';

import { useEffect, useMemo, useState } from 'react';

import { Close } from '@gds/Icons/Paths/Close';
import { Typography } from '@gds/Typography/Typography';
import { useAtom } from 'jotai';

import { errorBoxOpenAtom } from 'Atoms/App/Plp/ErrorBoxOpenAtom';

import styles from '../CallDealer.module.css';

const ErrorBox: React.FC<{
  strings: Record<string, any>;
  heycarId: string;
  vehicles: {
    heycarId: string;
    hasFinance: boolean;
    isReducedPrice: boolean;
  }[];
}> = ({ strings, vehicles, heycarId }) => {
  const [rowSize, setRowSize] = useState(1);
  const [rowIndex, setRowIndex] = useState(0);
  const [, setIsErrorBoxOpen] = useAtom(errorBoxOpenAtom);
  const vehicle = vehicles.find(vehicle => vehicle.heycarId === heycarId);

  useEffect(() => {
    const rowSize = window.innerWidth < 550 ? 1 : window.innerWidth < 1024 ? 2 : 3;
    setRowSize(rowSize);
    const currentIndex = vehicles ? vehicles.findIndex(v => v.heycarId === heycarId) : -1;
    const rowIndex = Math.floor(currentIndex / rowSize);
    setRowIndex(rowIndex);
  }, []);

  const handleErrorBoxClick = async (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleErrorBoxCloseClick = async (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsErrorBoxOpen(false);
  };

  const isDiscountErrorBox = useMemo(
    () =>
      (vehicles || []).some(
        v =>
          v.heycarId !== heycarId &&
          Math.floor(vehicles.indexOf(v) / rowSize) === rowIndex &&
          v.isReducedPrice,
      ) || vehicle?.isReducedPrice,
    [vehicles, heycarId, rowSize, rowIndex],
  );

  const isFinanceErrorBox = useMemo(
    () =>
      (vehicles || []).some(
        v =>
          v.heycarId !== heycarId &&
          Math.floor(vehicles.indexOf(v) / rowSize) === rowIndex &&
          v.hasFinance,
      ) || vehicle?.hasFinance,
    [vehicles, heycarId, rowSize, rowIndex],
  );
  const isDiscountFinanceErrorBox = useMemo(
    () =>
      (vehicles || []).some(
        v =>
          v !== vehicle &&
          Math.floor(vehicles.indexOf(v) / rowSize) === rowIndex &&
          v.hasFinance &&
          v.isReducedPrice,
      ) ||
      (vehicle?.hasFinance && vehicle?.isReducedPrice),
    [vehicles, vehicle, rowSize, rowIndex],
  );

  return (
    <div
      className={`${styles.errorBox} ${isDiscountErrorBox && styles.discountErrorBox} ${
        isFinanceErrorBox && styles.financeErrorBox
      } ${isDiscountFinanceErrorBox && styles.discountFinanceErrorBox}`}
      onClick={handleErrorBoxClick}
    >
      <div className={styles.errorBoxTitle}>
        <Typography Component="span" variant="h6">
          {strings.seeNumber}
        </Typography>
        <Close onClick={handleErrorBoxCloseClick} />
      </div>
      <Typography Component="span" variant="caption2" className={styles.redText}>
        {strings.callDealerError}
      </Typography>
    </div>
  );
};

export default ErrorBox;
