import { AllExperiments } from '@growthBookExperimentation/Entities/Experiments.entities';
import { FlagKey } from '@growthBookExperimentation/Entities/FlagKeys.entities';
import { getExperimentViewedEvents } from '@tracking/Utils/GetExperimentViewedEvents';
import { callStartToEvent } from '../../../tracking/src/Schemas/CallStart/CallStartToEvent';
import { callSubmitToEvent } from '../../../tracking/src/Schemas/CallSubmit/CallSubmitToEvent';
import { leadStartToEvent } from '../../../tracking/src/Schemas/Lead/LeadStart/LeadStartToEvent';
import { leadSubmitToEvent } from '../../../tracking/src/Schemas/Lead/LeadSubmit/LeadSubmitToEvent';
import { leadToEvent } from '../../../tracking/src/Schemas/Lead/LeadToEvent';
import { vehicleToListingEvent } from '../../../tracking/src/Schemas/Listing/VehicleToListingEvent';
import { vehicleToListingFeatureEvent } from '../../../tracking/src/Schemas/ListingFeature/VehicleToListingFeatureEvent';
import { marketingToEvent } from '../../../tracking/src/Schemas/Marketing/MarketingToEvent';
import { userContextToEvent } from '../../../tracking/src/Schemas/UserContext/UserContextToEvent';
import { LeadFormState } from '../Atoms/Pdp/FormState.atom';
import { SearchParamsType } from '../Atoms/SearchParams/SearchParams.atom';
import { TrackingDataType } from '../Entities/Tracking/Tracking.entity';
import { Vehicle } from '../Entities/Vehicle/Vehicle.entity';
import { experimentViewedToEvent } from '@tracking/Schemas/ExperimentViewed/ExperimentViewedToEvent';

interface Props {
  searchParams: SearchParamsType;
  userId: string | null | undefined;
  formState: LeadFormState;
  leadId: string;
  leadFormId: string;
  listingId: string | null;
  captiveId?: string;
  source?: string;
  vehicle: Vehicle;
  experiments?: AllExperiments;
  ctaLocation: string;
  leadAction: string;
  isInstantCallbackEnabled?: boolean;
  numSimilarVehicles?: number;
}

export function generateLeadTrackingData({
  searchParams,
  userId,
  formState,
  leadId,
  leadFormId,
  listingId,
  captiveId,
  source,
  vehicle,
  experiments,
  ctaLocation,
  leadAction,
  numSimilarVehicles,
  isInstantCallbackEnabled = false,
}: Props): Record<string, TrackingDataType> {
  const marketingDataObj = {
    marketingValues: { ...(searchParams ?? {}) },
  };
  const userData = {
    userId,
    values: { ...formState },
  };
  let extraData = {};

  if (leadAction === 'submit') {
    const keys = Object.keys(formState);
    const hasOneClickDriftrock = keys.includes('oneClickDriftrockChkBox');
    const hasAnyDriftrock = hasOneClickDriftrock || keys.includes('driftrockChkBox');
    const isAnyDriftrockChecked = formState.oneClickDriftrockChkBox || formState.driftrockChkBox;
    //formState cant be relied on alone to check for oneclick, because we don't have similar vehicles at the time the state is initialized
    const hasOneClick = keys.includes('oneClickChkBox') && Boolean(numSimilarVehicles);

    extraData = {
      has_oneClick_chkBox: hasOneClick,
      is_oneclick_chkBox_checked: formState.oneClickChkBox,
      num_similarVehicles: numSimilarVehicles,
      has_driftrock_chkBox: hasAnyDriftrock,
      is_driftrock_chkBox_checked: isAnyDriftrockChecked,
    };
  }

  const leadSubmitEventObj = {
    listingId: listingId as string,
    leadId,
    leadType: leadFormId as string,
    captiveId: captiveId as string,
  };
  const leadStartObj = {
    listingId,
    leadType: leadFormId as string,
    ctaLocation,
  };

  const leadEventObj = {
    vehicle,
    values: { ...formState },
    leadType: leadFormId as string,
    leadId,
    source,
  };
  // event actions
  const leadSubmitEvent = leadSubmitToEvent(leadSubmitEventObj);
  const leadStartEvent = leadStartToEvent(leadStartObj);
  const callStartEvent = callStartToEvent(leadStartObj);

  // event entities
  const userEvent = userContextToEvent(userData);
  const listingEvent = vehicleToListingEvent(vehicle);
  const marketingEvent = marketingToEvent(marketingDataObj);
  const listingFeatureEvent = vehicleToListingFeatureEvent(vehicle, extraData);
  const leadEvent = leadToEvent(leadEventObj);

  const selectedLeadFormExperimentIds: FlagKey[] = [
    'message-field-leadform-experiment',
    'uk-enrichment-leadform-experiment',
    'name-field-leadform-experiment',
    'dealername-leadform-experiment',
  ];

  const selectedExperimentViewedEvents = getExperimentViewedEvents(
    selectedLeadFormExperimentIds,
    experiments,
  );

  if (leadFormId === 'message-dealer') {
    const flag: FlagKey = 'message-lead-automatic-callback-test';
    const experiment = experiments?.find(exp => exp?.experimentId === flag);

    if (experiment) {
      selectedExperimentViewedEvents.push(
        experimentViewedToEvent({
          experimentId: flag,
          variationId: isInstantCallbackEnabled ? '1' : '0',
        }),
      );
    }
  }

  const contextStart = [
    listingEvent,
    listingFeatureEvent,
    userEvent,
    marketingEvent,

    ...((leadFormId === 'message-dealer' || leadFormId === 'request-callback') &&
    selectedExperimentViewedEvents.length > 0
      ? [...selectedExperimentViewedEvents]
      : []),
  ];

  const contextSubmit = [leadEvent, listingEvent, listingFeatureEvent, userEvent, marketingEvent];
  return {
    leadStart: { event: leadStartEvent, context: contextStart },
    leadSubmit: { event: leadSubmitEvent, context: contextSubmit },
    callStart: { event: callStartEvent, context: contextStart },
    callSubmit: { context: contextSubmit } as any,
  };
}

export const createEventWithCallNumber = (
  vehicleId: string,
  callNumber: string,
  ctaLocation: string,
) => {
  const leadSubmitEventObj = {
    listingId: vehicleId,
    leadId: '',
    leadType: 'call-dealer',
    callNumber: callNumber,
    ctaLocation: ctaLocation,
  };
  const callSubmitEvent = callSubmitToEvent(leadSubmitEventObj);
  return callSubmitEvent;
};
