const getDesktopItemGridSpan = (
  isCompactSearch: boolean,
  hasAutobiz: boolean,
  hasBuyOnline: boolean,
  postcodeRequired: boolean,
) => {
  if (isCompactSearch) return 12;
  if (hasAutobiz) return 2;
  if (hasBuyOnline) return 2;
  if (!postcodeRequired) return 4;
  return 3;
};

const getTabletItemGridSpan = (
  isCompactSearch: boolean,
  hasBuyOnline: boolean,
  postcodeRequired: boolean,
) => {
  if (isCompactSearch) return 12;
  if (hasBuyOnline || (!postcodeRequired && !hasBuyOnline)) return 3;
  if (!postcodeRequired) return 4;
  return 3;
};

export { getDesktopItemGridSpan, getTabletItemGridSpan };
