import { useEffect, useState } from 'react';

import { Vehicle } from '@core/Entities/Vehicle/Vehicle.entity';
import { useIsExperimentEnabled } from '@growthBookExperimentation/Hooks/useIsExperimetEnabled';

import { LeadType } from 'Components/Pdp/LeadCTAs/LeadCTAs.entity';

import { leadModalHeaderText } from '../Utils/Leadforms.util';

export type LeadTypeToMessageMap = Record<LeadType, string>;

/**
 * Custom hook to determine the header text for lead forms based on the lead type and vehicle information.
 * It also supports an experiment to include dealer names in the header text.
 *
 * @param {LeadType | LeadType[]} leadType - The type of lead or an array of lead types.
 * @param {Vehicle | null | undefined} vehicle - The vehicle information, which may include dealer details.
 * @param {Record<string, string>} [allmessages] - An object containing message strings for different lead types.
 *
 * @returns {Object} An object containing the header text and a map of lead types to their corresponding messages.
 * @returns {string} return.header - The header text for the lead form.
 * @returns {LeadTypeToMessageMap} return.leadTypeToMessageMap - A map of lead types to their corresponding messages.
 */
export default function useLeadFormHeaderTextExperiment(
  leadType: LeadType | LeadType[],
  vehicle: Vehicle | null | undefined,
  allmessages: Record<string, string>,
): object {
  const isDealerNameExperimentEnabled = useIsExperimentEnabled('dealername-leadform-experiment', [
    '0',
    '1',
  ]);

  const messages = { ...allmessages };

  const {
    bookAppointmentWith,
    messageDealer,
    messageDealerFinance,
    requestCallbackFrom,
    requestImagesFrom,
    requestVideosFrom,
    leasingOnlineHeader,
  } = messages;

  const newLeadTypeToMessageMap: LeadTypeToMessageMap = {
    'message-dealer': messageDealer,
    'message-dealer-finance': messageDealerFinance,
    'request-callback': requestCallbackFrom,
    'request-video': requestVideosFrom,
    'request-images': requestImagesFrom,
    'call-dealer': messageDealer,
    'book-appointment': bookAppointmentWith,
    'history-check': messageDealer, // Where do I find this lead form?
    'leasing-online': leasingOnlineHeader, // There is no dealer involved. Should it be 'Message heycar'?
  };

  const existingLeadTypeToMessageMap = {
    'message-dealer': leadModalHeaderText('message-dealer', messages),
    'message-dealer-finance': leadModalHeaderText('message-dealer-finance', messages),
    'request-callback': leadModalHeaderText('request-callback', messages),
    'request-video': leadModalHeaderText('request-video', messages),
    'request-images': leadModalHeaderText('request-images', messages),
    'call-dealer': leadModalHeaderText('call-dealer', messages),
    'book-appointment': leadModalHeaderText('book-appointment', messages),
    'history-check': leadModalHeaderText('history-check', messages),
    'leasing-online': leadModalHeaderText('leasing-online', messages),
  };

  const [state, setState] = useState<{
    header: string;
    leadTypeToMessageMap: LeadTypeToMessageMap;
  }>({
    header: !Array.isArray(leadType) ? leadModalHeaderText(leadType, messages) : '',
    leadTypeToMessageMap: newLeadTypeToMessageMap,
  });

  useEffect(() => {
    let header = '';

    if (!isDealerNameExperimentEnabled || !vehicle) {
      if (!Array.isArray(leadType)) {
        header = leadModalHeaderText(leadType, messages);
        existingLeadTypeToMessageMap[leadType] = leadModalHeaderText(leadType, messages);
      } else {
        header = leadModalHeaderText(leadType[0], messages);
        leadType.forEach(
          type => (existingLeadTypeToMessageMap[type] = leadModalHeaderText(type, messages)),
        );
      }
      setState(() => ({ header, leadTypeToMessageMap: existingLeadTypeToMessageMap }));
      return;
    }

    const dealerName = vehicle?.dealer?.name || '';

    const isHeycarStockDealer = /\b(Exclusively delivered by heycar|Delivered By Heycar)\b/gi.test(
      dealerName,
    );

    const updatedLeadTypeToMessageMap = { ...newLeadTypeToMessageMap };

    updatedLeadTypeToMessageMap['leasing-online'] = messageDealer;

    if (isHeycarStockDealer) {
      if (!Array.isArray(leadType)) {
        header = `${updatedLeadTypeToMessageMap[leadType]} heycar`;
        updatedLeadTypeToMessageMap[leadType] = `${updatedLeadTypeToMessageMap[leadType]} heycar`;
      } else {
        header = `${updatedLeadTypeToMessageMap[leadType[0]]} heycar`;
        leadType.forEach(
          type =>
            (updatedLeadTypeToMessageMap[type] = `${updatedLeadTypeToMessageMap[type]} heycar`),
        );
      }
    } else {
      if (!Array.isArray(leadType)) {
        header = `${updatedLeadTypeToMessageMap[leadType]} ${dealerName}`;
        updatedLeadTypeToMessageMap[
          leadType
        ] = `${updatedLeadTypeToMessageMap[leadType]} ${dealerName}`;
      } else {
        header = `${updatedLeadTypeToMessageMap[leadType[0]]} ${dealerName}`;
        leadType.forEach(
          type =>
            (updatedLeadTypeToMessageMap[
              type
            ] = `${updatedLeadTypeToMessageMap[type]} ${dealerName}`),
        );
      }
    }

    setState(() => ({ header, leadTypeToMessageMap: updatedLeadTypeToMessageMap }));
  }, [vehicle, isDealerNameExperimentEnabled]);

  return state;
}
